import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CHECK_USER_CAN_BE_ADMIN } from "../../services/Api";

export const checkAdminUsers = createAsyncThunk(
  "checkAdminUsers",
  async ({ emailId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${CHECK_USER_CAN_BE_ADMIN}/${emailId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error checking admin user:", error);
      return rejectWithValue(error.response?.data ||
        error?.message ||
        "Error checking admin user");
    }
  }
);

const fetchAllUsersSlice = createSlice({
  name: "allUsers",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder.addCase(checkAdminUsers.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    });
    builder.addCase(checkAdminUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(checkAdminUsers.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
      state.errorMessage = action.payload;
    });
  },
});

export default fetchAllUsersSlice.reducer;
