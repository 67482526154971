import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  setUser,
  getPhoto,
  setJwtToken,
} from "../../features/slices/HomeSlice";

export const useAuthentication = () => {
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (accounts.length === 0 && inProgress === "none") {
          const loginResponse = await instance.loginRedirect({
            scopes: ["https://database.windows.net/user_impersonation"],
          });
          const account = loginResponse.account;
          const accessToken = loginResponse.accessToken;
          instance.setActiveAccount(account);
          const serializedAccount = serializeAccount(account);
          sessionStorage.setItem("JwtToken", accessToken);
          dispatch(setUser(serializedAccount));
          dispatch(setJwtToken(accessToken));
          dispatch(getPhoto());

          setToken(accessToken);
          setUserDetails(serializedAccount);
        } else if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["https://database.windows.net/user_impersonation"],
            account: accounts[0],
          });
          const accessToken = tokenResponse.accessToken;
          const serializedAccount = serializeAccount(accounts[0]);
          dispatch(setUser(serializedAccount));
          dispatch(setJwtToken(accessToken));
          dispatch(getPhoto());

          setToken(accessToken);
          setUserDetails(serializedAccount);
        }
      } catch (error) {
        console.error("Authentication error:", error);
      }
    };

    initializeAuth();
  }, [accounts, inProgress, instance, dispatch]);

  const serializeAccount = (account) => ({
    ...account,
    tenantProfiles: account.tenantProfiles
      ? Object.fromEntries(account.tenantProfiles)
      : {},
  });

  return { userDetails, token };
};
