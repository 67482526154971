import React from "react";
import { MotifLabel, MotifButton } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./errorModal.scss";

const ErrorModal = ({
  setName,
  handleButtonClick,
  deleteButtonLabel,
  labelText,
}) => {
  return (
    <div className="modal-overlay">
      <div className="error-modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
        <MotifLabel>{labelText || "Some unknown error occurred"}</MotifLabel>
        </div>
        <div className="modal-footer">
          <MotifButton
            onClick={handleButtonClick}
            className="button delete-modal-button"
          >
            {deleteButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

ErrorModal.propTypes = {
  setName: PropTypes.string.isRequired,
  deleteButtonLabel: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default ErrorModal;
