import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { FETCH_KBA_URL } from "../../services/Api";

export const fetchKba = createAsyncThunk(
  "fetchKba",
  async ({ token, langCode, kbaID, version }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${FETCH_KBA_URL}?version=${version}&KBAId=${kbaID}&langCode=${langCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const kbaSlice = createSlice({
  name: "kba",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKba.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(fetchKba.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchKba.rejected, (state, action) => {
        console.error("Error:", action);
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload || "An unexpected error occurred";
      });
  },
});

export default kbaSlice.reducer;