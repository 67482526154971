import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import {
  setUser,
  getPhoto,
  setJwtToken,
  resetLogoutFlag,
} from "../../features/slices/HomeSlice";
import { MotifButton } from "@ey-xd/motif-react";
import { RightArrow } from "../../constants/icons/Icons";
import LandingPageData from "../../constants/languages/en/translations.json";
import BackgroundImage from "../../assets/images/Home.png";
import EYLogo from "../../assets/images/EY_Logo.png";
import "./Home.scss";

const VERSION_NUMBER = "v3.17.12"; //New Version number based on the release
const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const hasLoggedOut = useSelector((state) => state.user.hasLoggedOut);

  const serializeAccount = (account) => ({
    ...account,
    tenantProfiles: account.tenantProfiles
      ? Object.fromEntries(account.tenantProfiles)
      : {},
  });

  const handleTokenAcquisition = useCallback(
    async (account) => {
      try {
        if (!instance.getActiveAccount()) {
          await instance.initialize();
        }

        const tokenResponse = await instance.acquireTokenSilent({
          scopes: ["https://database.windows.net/user_impersonation"],
          account,
        });
        const token = tokenResponse.accessToken;
        const serializedAccount = serializeAccount(account);
        dispatch(setUser(serializedAccount));
        dispatch(setJwtToken(token));
        dispatch(getPhoto());

        navigate("/all-projects", { state: { token } });
      } catch (error) {
        console.error("Failed to acquire token silently", error);
      }
    },
    [instance, dispatch, navigate]
  );

  useEffect(() => {
    const initializeAndAcquireToken = async () => {
      if (hasLoggedOut) {
        console.log("User has logged out, skipping automatic re-login");
        return;
      }
      if (accounts && accounts.length > 0) {
        try {
          await instance.initialize();
          await handleTokenAcquisition(accounts[0]);
        } catch (error) {
          console.error("Initialization or token acquisition failed", error);
        }
      } else {
        console.error("No accounts available for token acquisition");
      }
    };

    initializeAndAcquireToken();
  }, [accounts, instance, handleTokenAcquisition, hasLoggedOut]);

  const onNext = useCallback(async () => {
    if (hasLoggedOut) {
      console.log("User has logged out, resetting logout flag");
      dispatch(resetLogoutFlag());
    }

    if (!instance) {
      console.error("MSAL instance is not initialized");
      return;
    }

    try {
      if (accounts && accounts.length > 0) {
        console.log("Existing account found, acquiring token...");
        await instance.initialize();
        await handleTokenAcquisition(accounts[0]);
      } else if (inProgress === "none") {
        console.log("No accounts found, initiating loginRedirect...");
        await instance.loginRedirect({
          scopes: ["https://database.windows.net/user_impersonation"],
        });
      } else {
        console.error("No accounts available and login is in progress");
      }
    } catch (error) {
      const errorMessages = {
        popup_window_error: "Popup window error. Ensure popups are enabled.",
        user_cancelled: "User cancelled the authentication flow.",
      };

      console.error(
        errorMessages[error.errorCode] ||
          "Login failed due to an unexpected error",
        error
      );
    }
  }, [
    instance,
    accounts,
    handleTokenAcquisition,
    hasLoggedOut,
    dispatch,
    inProgress,
  ]);

  return (
    <div className="landingPage-home-section">
      <div className="landingPage-img-section">
        <img
          className="landingPage-background-img"
          src={BackgroundImage}
          alt="background"
        />
      </div>
      <div className="landingPage-text-section">
        <div className="landingPage-text-section-body">
          <img src={EYLogo} alt="ey-logo" />
          <h1 className="motif-h4-default-bold">
            {LandingPageData.landingPageHeader2}
          </h1>
          <p className="motif-body1-default-light">
            {LandingPageData.landingPageHeader3}
          </p>
          <MotifButton className="landingPage-nextButton" onClick={onNext}>
            {LandingPageData.landingPageNext}
            <RightArrow />
          </MotifButton>
        </div>
        <div className="version-number">
          <Typography variant="body2" align="right">
            Version {VERSION_NUMBER}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
