import React from "react";
import PropTypes from "prop-types";
import { Info } from "../../constants/icons/Icons";

const infoIconButtonStyle = {
  background: "none",
  border: "none",
  cursor: "pointer",
  padding: 0,
  display: "inline-flex",
  alignItems: "center",
};

const InfoIcon = ({ handleClick }) => {
  return (
    <button
      onClick={handleClick}
      style={infoIconButtonStyle}
      aria-label="Information"
    >
      <Info />
    </button>
  );
};

InfoIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default InfoIcon;