import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import AllProjects from "../pages/allProjects/AllProjects";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/all-Projects" element={<AllProjects />}/>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
