import React, { useState, useEffect } from "react";
import Header from "../../components/headers/Header";
import { useSelector } from "react-redux";
import Disclaimer from "./Disclaimer";
import LandingPage from "./LandingPage";
import "./Home.scss";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const userState = useSelector((state) => state.user);

  const handleAcknowledge = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!userState.user) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [userState.user]);

  return (
    <div className="homePage-container">
      <Header />
      {!userState.user && showModal && (
        <Disclaimer onAgree={handleAcknowledge} />
      )}
      <LandingPage />
    </div>
  );
};

export default Home;