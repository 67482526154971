import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { MS_GRAPH_URL } from "../../services/Api";
import { getToken } from "../../constants/config/getToken";

// Utility function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer) => {
  if (!(buffer instanceof ArrayBuffer)) {
    throw new Error("Invalid input: Expected an ArrayBuffer");
  }

  const len = buffer.byteLength;

  // Validate buffer size
  const MAX_SIZE = 1000000; // Adjust maximum size as needed
  if (len > MAX_SIZE) {
    throw new Error(`ArrayBuffer exceeds maximum allowed size of ${MAX_SIZE}`);
  }

  // Convert ArrayBuffer to Base64
  try {
    return window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
  } catch (error) {
    throw new Error("Failed to convert ArrayBuffer to Base64");
  }
};

// Async thunk to fetch user photo
export const getPhoto = createAsyncThunk(
  "user/getPhoto",
  async (_, thunkAPI) => {
    try {
      const token = await getToken();
      const response = await axios.get(MS_GRAPH_URL.GetUserPhoto, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const photo = arrayBufferToBase64(response.data);
      return photo;
    } catch (error) {
      console.error("getPhoto API call failed", error);
      const errorMessage = error.response?.data || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "user/fetchOrganization",
  async (organizationId, thunkAPI) => {
    try {
      const token = await getToken();
      const response = await axios.get(
        MS_GRAPH_URL.GetOrganization(organizationId),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching organization", error);
      const errorMessage = error.response?.data || "An error occurred";
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Initial state
const initialState = {
  user: null,
  photo: "",
  isAuthenticated: false,
  jwtToken: null, // Token starts as null
  organization: {},
  hasLoggedOut: false, // Add this flag
};

// Create slice
const homeSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.hasLoggedOut = false;
    },
    setJwtToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    logoutUser: (state) => {
      Object.assign(state, {
        ...initialState,
        hasLoggedOut: true, // Maintain logout flag
      });
      sessionStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("jwtToken");
      setJwtToken(null); // Remove token from storage
    },
    resetLogoutFlag: (state) => {
      state.hasLoggedOut = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPhoto.fulfilled, (state, action) => {
      state.photo = action.payload;
    });
    builder.addCase(getPhoto.rejected, (state, action) => {
      console.error("getPhoto rejected:", action.payload);
    });
    builder.addCase(fetchOrganization.fulfilled, (state, action) => {
      state.organization = action.payload;
    });
  },
});

export const { setUser, logoutUser, setJwtToken, resetLogoutFlag } =
  homeSlice.actions;

export default homeSlice.reducer;
