import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ALL_INSTANCES_URL } from "../../services/Api";

export const fetchAllInstances = createAsyncThunk(
  "allInstances/fetchAllInstances",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(ALL_INSTANCES_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      const errorMessage =
        error.response?.data?.title ||
        error.message ||
        "Error fetching projects";
      return rejectWithValue(errorMessage);
    }
  }
);

const allInstanceSlice = createSlice({
  name: "allInstances",
  initialState: {
    isInstanceApiLoading: false,
    data: null,
    isInstanceApiError: false,
    instanceApiErrorMessage: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInstances.pending, (state, action) => {
        state.isInstanceApiLoading = true;
        state.isInstanceApiError = false;
        state.instanceApiErrorMessage = null;
      })
      .addCase(fetchAllInstances.fulfilled, (state, action) => {
        state.isInstanceApiLoading = false;
        state.data = action.payload;
        state.isInstanceApiError = false;
      })
      .addCase(fetchAllInstances.rejected, (state, action) => {
        console.error("Error", action.payload);
        state.isInstanceApiLoading = false;
        state.isInstanceApiError = true;
        state.instanceApiErrorMessage =
          action.payload || "An unexpected error occurred";
      });
  },
});

export default allInstanceSlice.reducer;
