const API_BASE_URL = window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL;
const GRAPH_BASE_URL = "https://graph.microsoft.com/v1.0";

export const ALL_INSTANCES_URL = `${API_BASE_URL}Instance/GetAllInstances`;
export const MS_GRAPH_URL = {
  GetUserPhoto: `${GRAPH_BASE_URL}/me/photo/$value`,
  GetOrganization: (organizationId) =>
    `${GRAPH_BASE_URL}/organization/${organizationId}?$select=displayName`,
};
export const FETCH_KBA_URL = `${API_BASE_URL}KBA/GetKBA`;

//Users URL
export const FETCH_AD_USERS = "https://graph.microsoft.com/v1.0/users";
export const CHECK_USER_CAN_BE_ADMIN = `${API_BASE_URL}User/CheckIfUserCanBeAdmin`;

//list of projects from Instance
export const GET_ALL_PROJECTS = `${API_BASE_URL}Project/GetAll`;