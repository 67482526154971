import React from "react";
const svgStyle = {
  marginRight: "10px",
};

export const PrivacyNote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={svgStyle}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4375 10.5C5.4375 10.1893 5.68934 9.9375 6 9.9375L12 9.9375C12.3107 9.9375 12.5625 10.1893 12.5625 10.5C12.5625 10.8107 12.3107 11.0625 12 11.0625L6 11.0625C5.68934 11.0625 5.4375 10.8107 5.4375 10.5Z"
        fill="#656579"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4375 7.5C5.4375 7.18934 5.68934 6.9375 6 6.9375H7.5C7.81066 6.9375 8.0625 7.18934 8.0625 7.5C8.0625 7.81066 7.81066 8.0625 7.5 8.0625H6C5.68934 8.0625 5.4375 7.81066 5.4375 7.5Z"
        fill="#656579"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.4375 13.5C5.4375 13.1893 5.68934 12.9375 6 12.9375H9C9.31066 12.9375 9.5625 13.1893 9.5625 13.5C9.5625 13.8107 9.31066 14.0625 9 14.0625H6C5.68934 14.0625 5.4375 13.8107 5.4375 13.5Z"
        fill="#656579"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0.1875C7.81066 0.1875 8.0625 0.43934 8.0625 0.75V3.75C8.0625 4.06066 7.81066 4.3125 7.5 4.3125C7.18934 4.3125 6.9375 4.06066 6.9375 3.75V2.8125H4.5C3.98223 2.8125 3.5625 3.23223 3.5625 3.75V15C3.5625 15.5178 3.98223 15.9375 4.5 15.9375H13.5C14.0178 15.9375 14.4375 15.5178 14.4375 15V3.75C14.4375 3.23223 14.0178 2.8125 13.5 2.8125H10.875C10.5643 2.8125 10.3125 2.56066 10.3125 2.25C10.3125 1.93934 10.5643 1.6875 10.875 1.6875H13.5C14.6391 1.6875 15.5625 2.61091 15.5625 3.75V15C15.5625 16.1391 14.6391 17.0625 13.5 17.0625H4.5C3.36091 17.0625 2.4375 16.1391 2.4375 15V3.75C2.4375 2.61091 3.36091 1.6875 4.5 1.6875H6.9375V0.75C6.9375 0.43934 7.18934 0.1875 7.5 0.1875Z"
        fill="#656579"
      />
    </svg>
  );
};

export const HelpDesk = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={svgStyle}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4378 8.18681C2.47181 4.59162 5.39678 1.68768 9 1.68768C12.6032 1.68768 15.5282 4.59162 15.5622 8.1868C16.4472 8.43727 17.0625 9.24618 17.0625 10.1712V11.5788C17.0625 12.5038 16.4473 13.3126 15.5625 13.5632V13.8752C15.5625 15.0143 14.6391 15.9377 13.5 15.9377H11.7165C11.4848 16.5931 10.8597 17.0627 10.125 17.0627H7.875C6.94301 17.0627 6.1875 16.3071 6.1875 15.3752C6.1875 14.4432 6.94301 13.6877 7.875 13.6877H10.125C10.8597 13.6877 11.4848 14.1573 11.7165 14.8127H13.5C14.0178 14.8127 14.4375 14.393 14.4375 13.8752V13.8454L14.1956 13.9059C14.1956 13.9059 14.1956 13.9059 14.1956 13.9059C13.5565 14.0657 12.9375 13.5823 12.9375 12.9236V8.8264C12.9375 8.1677 13.5565 7.68431 14.1956 7.84413L14.4265 7.90186C14.2469 5.06112 11.8861 2.81268 9 2.81268C6.11396 2.81268 3.75307 5.06112 3.57348 7.90186L3.8044 7.84413C3.80438 7.84413 3.80441 7.84413 3.8044 7.84413C4.44347 7.68434 5.0625 8.16769 5.0625 8.8264V12.9236C5.0625 13.5824 4.44351 14.0657 3.80443 13.9059C3.80442 13.9059 3.80444 13.9059 3.80443 13.9059L2.49977 13.5798C1.58163 13.3502 0.9375 12.5253 0.9375 11.5788V10.1712C0.9375 9.24619 1.55277 8.43728 2.4378 8.18681ZM14.0625 8.97048V12.7796L15.2274 12.4883C15.2274 12.4883 15.2274 12.4883 15.2274 12.4883C15.6447 12.384 15.9375 12.009 15.9375 11.5788V10.1712C15.9375 9.741 15.6447 9.36606 15.2274 9.2617C15.2274 9.2617 15.2274 9.26171 15.2274 9.2617L14.0625 8.97048ZM3.9375 8.97048L2.77263 9.2617C2.35526 9.36605 2.0625 9.741 2.0625 10.1712V11.5788C2.0625 12.009 2.35526 12.384 2.77263 12.4883L3.9375 12.7796V8.97048ZM7.875 14.8127C7.56435 14.8127 7.3125 15.0645 7.3125 15.3752C7.3125 15.6858 7.56435 15.9377 7.875 15.9377H10.125C10.4356 15.9377 10.6875 15.6858 10.6875 15.3752C10.6875 15.0645 10.4356 14.8127 10.125 14.8127H7.875Z"
        fill="#656579"
      />
    </svg>
  );
};

export const UserProfile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      style={svgStyle}
    >
      <rect x="2.5" y="2.5" width="39" height="39" rx="19.5" fill="#F3F3F5" />
      <rect x="2.5" y="2.5" width="39" height="39" rx="19.5" stroke="#E6E6E9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 14.75C20.2051 14.75 18.75 16.2051 18.75 18C18.75 19.7949 20.2051 21.25 22 21.25C23.7949 21.25 25.25 19.7949 25.25 18C25.25 16.2051 23.7949 14.75 22 14.75ZM24.8583 21.7941C26.0073 20.9271 26.75 19.5503 26.75 18C26.75 15.3766 24.6234 13.25 22 13.25C19.3766 13.25 17.25 15.3766 17.25 18C17.25 19.5503 17.9927 20.9271 19.1417 21.7941C16.2761 22.9317 14.25 25.7293 14.25 29V30C14.25 30.4142 14.5858 30.75 15 30.75C15.4142 30.75 15.75 30.4142 15.75 30V29C15.75 25.5482 18.5482 22.75 22 22.75C25.4518 22.75 28.25 25.5482 28.25 29V30C28.25 30.4142 28.5858 30.75 29 30.75C29.4142 30.75 29.75 30.4142 29.75 30V29C29.75 25.7293 27.7239 22.9317 24.8583 21.7941Z"
        fill="#656579"
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05806 4.55806C6.81398 4.80214 6.81398 5.19786 7.05806 5.44194L11.6161 10L7.05806 14.5581C6.81398 14.8021 6.81398 15.1979 7.05806 15.4419C7.30214 15.686 7.69786 15.686 7.94194 15.4419L12.9419 10.4419C13.186 10.1979 13.186 9.80214 12.9419 9.55806L7.94194 4.55806C7.69786 4.31398 7.30214 4.31398 7.05806 4.55806Z"
        fill="#AFAEBA"
      />
    </svg>
  );
};

export const BlueTick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.72466 10.3914C3.96873 10.1474 4.36446 10.1474 4.60854 10.3914L7.49993 13.2828L15.3913 5.39143C15.6354 5.14735 16.0311 5.14735 16.2752 5.39143C16.5193 5.63551 16.5193 6.03124 16.2752 6.27532L7.94187 14.6086C7.6978 14.8527 7.30207 14.8527 7.05799 14.6086L3.72466 11.2753C3.48058 11.0312 3.48058 10.6355 3.72466 10.3914Z"
        fill="#0F69AE"
      />
    </svg>
  );
};

export const Cancel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.18923 5.18919C5.4333 4.94511 5.82903 4.94511 6.07311 5.18919L10 9.11612L13.927 5.18919C14.171 4.94511 14.5668 4.94511 14.8108 5.18919C15.0549 5.43327 15.0549 5.829 14.8108 6.07307L10.8839 10L14.8108 13.9269C15.0549 14.171 15.0549 14.5667 14.8108 14.8108C14.5668 15.0549 14.171 15.0549 13.927 14.8108L10 10.8839L6.07311 14.8108C5.82903 15.0549 5.4333 15.0549 5.18923 14.8108C4.94515 14.5667 4.94515 14.171 5.18923 13.9269L9.11615 10L5.18923 6.07307C4.94515 5.829 4.94515 5.43327 5.18923 5.18919Z"
        fill="#23232F"
      />
    </svg>
  );
};

export const View = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#C3C3CB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.93 19.0898C22.1511 12.9145 13.8487 12.9145 11.0699 19.0898C10.9282 19.4046 10.5582 19.5449 10.2434 19.4033C9.92866 19.2616 9.78831 18.8916 9.92996 18.5768C13.1511 11.4188 22.8487 11.4188 26.0699 18.5768C26.2115 18.8916 26.0712 19.2616 25.7564 19.4033C25.4416 19.5449 25.0716 19.4046 24.93 19.0898Z"
        fill="#AFAEBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9999 17.7917C19.0354 17.7917 19.8749 18.6311 19.8749 19.6667C19.8749 20.7022 19.0354 21.5417 17.9999 21.5417C16.9644 21.5417 16.1249 20.7022 16.1249 19.6667C16.1249 18.6311 16.9644 17.7917 17.9999 17.7917ZM21.1249 19.6667C21.1249 17.9408 19.7258 16.5417 17.9999 16.5417C16.274 16.5417 14.8749 17.9408 14.8749 19.6667C14.8749 21.3926 16.274 22.7917 17.9999 22.7917C19.7258 22.7917 21.1249 21.3926 21.1249 19.6667Z"
        fill="#AFAEBA"
      />
    </svg>
  );
};

export const Edit = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#C3C3CB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1802 15.4538C26.0751 14.5588 26.0751 13.1078 25.1802 12.2129L24.0017 11.0344C23.1067 10.1394 21.6557 10.1394 20.7608 11.0344L11.5144 20.2807C11.1339 20.6613 10.8994 21.1635 10.8521 21.6995L10.6503 23.9832C10.5705 24.8874 11.3271 25.6441 12.2313 25.5642L14.515 25.3625C15.051 25.3151 15.5533 25.0807 15.9338 24.7002L25.1802 15.4538ZM24.2963 13.0968C24.7031 13.5036 24.7031 14.1631 24.2963 14.5699L23.5049 15.3613L20.8533 12.7096L21.6446 11.9183C22.0514 11.5115 22.711 11.5115 23.1178 11.9183L24.2963 13.0968ZM19.9694 13.5935L22.621 16.2452L15.0499 23.8163C14.877 23.9892 14.6487 24.0958 14.405 24.1173L12.1213 24.3191C11.9922 24.3305 11.8841 24.2224 11.8955 24.0932L12.0972 21.8095C12.1187 21.5659 12.2253 21.3376 12.3983 21.1646L19.9694 13.5935Z"
        fill="#AFAEBA"
      />
    </svg>
  );
};

export const Add = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.375C10.3452 4.375 10.625 4.65482 10.625 5V9.375H15C15.3452 9.375 15.625 9.65482 15.625 10C15.625 10.3452 15.3452 10.625 15 10.625H10.625V15C10.625 15.3452 10.3452 15.625 10 15.625C9.65482 15.625 9.375 15.3452 9.375 15V10.625H5C4.65482 10.625 4.375 10.3452 4.375 10C4.375 9.65482 4.65482 9.375 5 9.375H9.375V5C9.375 4.65482 9.65482 4.375 10 4.375Z"
        fill="#AFAEBA"
      />
    </svg>
  );
};

export const Search = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7247 13.7247C13.9688 13.4806 14.3645 13.4806 14.6086 13.7247L17.9419 17.0581C18.186 17.3021 18.186 17.6979 17.9419 17.9419C17.6979 18.186 17.3021 18.186 17.0581 17.9419L13.7247 14.6086C13.4806 14.3645 13.4806 13.9688 13.7247 13.7247Z"
        fill="#9897A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C10.8381 15.2083 12.35 14.5304 13.4445 13.4331C14.5351 12.3395 15.2083 10.8323 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125ZM1.875 9.16667C1.875 5.13959 5.13959 1.875 9.16667 1.875C13.1937 1.875 16.4583 5.13959 16.4583 9.16667C16.4583 11.1765 15.6443 12.9975 14.3295 14.3158C13.0101 15.6387 11.1835 16.4583 9.16667 16.4583C5.13959 16.4583 1.875 13.1937 1.875 9.16667Z"
        fill="#9897A6"
      />
    </svg>
  );
};
export const Delete = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#C3C3CB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7749 14.8845C25.1149 14.9442 25.342 15.2683 25.2822 15.6083L23.6197 25.0635C23.6197 25.0635 23.6197 25.0635 23.6197 25.0635C23.4271 26.1592 22.4753 26.9584 21.3627 26.9584H14.6373C13.5247 26.9584 12.5729 26.1592 12.3802 25.0635L10.7178 15.6083C10.658 15.2683 10.8851 14.9442 11.2251 14.8845C11.5651 14.8247 11.8891 15.0518 11.9489 15.3918L13.6114 24.847C13.6989 25.3451 14.1316 25.7084 14.6373 25.7084H21.3627C21.8684 25.7084 22.3011 25.3451 22.3886 24.8471L24.0511 15.3918C24.1109 15.0518 24.4349 14.8247 24.7749 14.8845Z"
        fill="#AFAEBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8542 10.2917C16.2789 10.2917 15.8125 10.7581 15.8125 11.3334V12.375H20.1875V11.3334C20.1875 10.7581 19.7211 10.2917 19.1458 10.2917H16.8542ZM14.5625 12.375V11.3334C14.5625 10.0677 15.5885 9.04169 16.8542 9.04169H19.1458C20.4115 9.04169 21.4375 10.0677 21.4375 11.3334V12.375H25.5C25.8452 12.375 26.125 12.6548 26.125 13C26.125 13.3452 25.8452 13.625 25.5 13.625H10.5C10.1548 13.625 9.875 13.3452 9.875 13C9.875 12.6548 10.1548 12.375 10.5 12.375H14.5625Z"
        fill="#AFAEBA"
      />
    </svg>
  );
};

export const RightTick = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72466 10.3914C3.96873 10.1473 4.36446 10.1473 4.60854 10.3914L7.49993 13.2828L15.3913 5.39137C15.6354 5.14729 16.0311 5.14729 16.2752 5.39137C16.5193 5.63545 16.5193 6.03118 16.2752 6.27525L7.94187 14.6086C7.6978 14.8527 7.30207 14.8527 7.05799 14.6086L3.72466 11.2753C3.48058 11.0312 3.48058 10.6354 3.72466 10.3914Z"
        fill="white"
      />
    </svg>
  );
};

export const Info = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      ></path>
    </svg>
  );
};

export const Globe = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 36"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"
      ></path>
    </svg>
  );
};

export const Sort = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64647 0.646447C3.84173 0.451184 4.15831 0.451184 4.35357 0.646447L7.68691 3.97978C7.88217 4.17504 7.88217 4.49162 7.68691 4.68689C7.49165 4.88215 7.17506 4.88215 6.9798 4.68689L4.00002 1.70711L1.02024 4.68689C0.824978 4.88215 0.508396 4.88215 0.313134 4.68689C0.117871 4.49162 0.117871 4.17504 0.313134 3.97978L3.64647 0.646447Z"
        fill="#656579"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64647 13.3536C3.84173 13.5488 4.15831 13.5488 4.35357 13.3536L7.68691 10.0202C7.88217 9.82496 7.88217 9.50838 7.68691 9.31311C7.49165 9.11785 7.17506 9.11785 6.9798 9.31311L4.00002 12.2929L1.02024 9.31311C0.824978 9.11785 0.508396 9.11785 0.313134 9.31311C0.117871 9.50838 0.117871 9.82496 0.313134 10.0202L3.64647 13.3536Z"
        fill="#656579"
      />
    </svg>
  );
};