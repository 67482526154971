import { configureStore } from "@reduxjs/toolkit";
import allInstancesReducer from "./slices/allInstances";
import homeSliceReducer from "./slices/HomeSlice"; 
import kbaSliceReducer from "./slices/kbaSlice"; 
import fetchAllUsersReducer from "./slices/UserData";
import fetchAllProjectsReducer from "./slices/projectSlice";

export const store = configureStore({
  reducer: {
    allInstances: allInstancesReducer,
    user: homeSliceReducer,
    kba: kbaSliceReducer,
    allUsers: fetchAllUsersReducer,
    allProjects: fetchAllProjectsReducer,
  },
});
