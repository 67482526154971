

export const instanceCountries = [
  {
    name: "USA",
    id: 1,
  },
  {
    name: "Europe",
    id: 2,
  },
  {
    name: "Australia",
    id: 3,
  },
  {
    name: "China",
    id: 4,
  },
  
];
export const optionData = [
  {
    label: "Instance",
    value: "Instance",
  },
  {
    label: "Portal",
    value: "Portal",
  },
];
